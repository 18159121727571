import axios, { AxiosResponse } from 'axios';

import {
  CertificateType,
  CLASSIFICATION,
  DiamondClarity,
  DiamondColor,
  DiamondShape,
  FieldBraceletStyleFromSimple,
  FieldBrandFromSimple,
  FieldCertificateFromSimple,
  FieldCertificateFromSimpleGemstone,
  FieldClarityFromSimple,
  FieldClarityFromSimpleGemstone,
  FieldColorFromSimple,
  FieldColorFromSimpleGemstone,
  FieldConditionFromSimple,
  FieldEarringsStyleFromSimple,
  FieldMetaTypeFromSimple,
  FieldNecklaceStyleFromSimple,
  FieldShapeFromSimple,
  FieldShapeFromSimpleGemstone,
  FieldStyleFromSimple,
  FieldWatchBrandFromSimple,
  GemstoneColor,
  ItemTypes,
  MetalTypes,
  SubsetForEarringsStyle,
  SubsetForNecklaceStyle,
  Subsets,
} from '../data/ItemDetails';

import { getSessionData, isIframe, toSnakeCase } from '../lib/commonUtils';
import type { ItemInputsTypes, SubmitState } from '../slices/submitSlice';
import { CookieStore } from './cookieStore';

const { REACT_APP_SITE_URL } = process.env;

const DEFAULT_PHONE = '0000000001';

interface ISubmitItemResponse {
  item: {
    bundle: {
      id: number;
    };
    id: string;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: {};
}

export default class WorthyAPI {
  static defaultOptions = {
    withCredentials: true,
  };

  static async ADO() {
    return axios.get(`${REACT_APP_SITE_URL}/api/v2/sellers/ado`, this.defaultOptions);
  }

  static async storeStateInBackend(
    state: any,
    user: any,
    trigger: string,
    credential = '',
    data?: any,
  ) {
    return axios
      .post(
        `${process.env.REACT_APP_SUBMIT_BE_URL}/store-state-data`,
        { state, user, credential, trigger, data },
        this.defaultOptions,
      )
      .catch((err: any) => {
        // Just log the error
        if (window.rollbar && window.rollbar.error)
          window.rollbar.error('Error sending state', err);
        console.error('Error sending state', err);
      });
  }

  static async storegClientIdInBackend(submissionId: string, gClientId: string) {
    return axios
      .post(
        `${process.env.REACT_APP_SUBMIT_BE_URL}/store-gcid`,
        { submissionId, gClientId },
        this.defaultOptions,
      )
      .catch((err: any) => {
        // Just log the error
        if (window.rollbar && window.rollbar.error) window.rollbar.error('Error sending gcid', err);
        console.error('Error sending gcid', err);
      });
  }

  static async createItem(params: SubmitState) {
    switch (params.type) {
      case ItemTypes.Earrings:
        return this.createEarringsItem(params);
      case ItemTypes.Necklace:
        return this.createNecklaceItem(params);
      case ItemTypes.LooseDiamond:
        return this.createLooseDiamondItem(params);
      case ItemTypes.Watch:
        return this.createWatchItem(params);
      case ItemTypes.Bracelet:
        return this.createBraceletItem(params);
      default:
        return this.createRingItem(params);
    }
  }

  static getGemstoneStoneSubset(
    params: ItemInputsTypes,
    carat: number,
    caratTo: number | undefined,
    color: string,
    clarity: string,
    type: string,
    typeId?: string,
  ) {
    return {
      type,
      properties_set: {
        item_type: typeId ?? '',
        gemstone_color_shade: 'gemstone_color_shade_na',
        gemstone_cut_grade: 'gemstone_cut_grade_very_good',
        gemstone_polish: 'gemstone_polish_very_good',
        gemstone_symmetry: 'gemstone_symmetry_very_good',
        gemstone_fluorescence_intensity: 'gemstone_fluorescence_intensity_none',
        gemstone_diamond_treated: 'gemstone_diamond_treated_no',
        gemstone_clarity_enhanced: 'gemstone_clarity_enhanced_no',
        gemstone_color_enhanced: 'gemstone_color_enhanced_no',
        gemstone_chips: 'gemstone_chips_none',
        gemstone_inclusions_location: 'gemstone_inclusions_location_none',
        gemstone_inclusions_level: 'gemstone_inclusions_level_eye_clean',
        gemstone_culet_size: 'gemstone_culet_size_none',
        gemstone_culet_condition: 'gemstone_culet_condition_natural',
        gemstone_certificate: FieldCertificateFromSimpleGemstone[params.certificate],
        gemstone_certificate_number: params.certificateNumber,
        gemstone_setting_type: 'gemstone_setting_type_prong',
        gemstone_carat_weight: `${carat}`,
        gemstone_carat_weight_to: `${caratTo ?? ''}`,
        gemstone_shape: FieldShapeFromSimpleGemstone[params.shape],
        gemstone_color: FieldColorFromSimpleGemstone[color],
        gemstone_clarity: FieldClarityFromSimpleGemstone[clarity],
        gemstone_type: 'gemstone_type_other',
        stone_type: 'stone_type_other',
      },
    };
  }

  static createRingItem(params: ItemInputsTypes) {
    const { color, clarity, carat, caratTo, isLabGrown } = this.extracted(params);

    let centerStoneSubset: any = {
      type: 'diamond_center_stone',
      properties_set: {
        diamond_color_shade: 'diamond_color_shade_na',
        diamond_cut_grade: 'diamond_cut_grade_very_good',
        diamond_polish: 'diamond_polish_very_good',
        diamond_symmetry: 'diamond_symmetry_very_good',
        diamond_fluorescence_intensity: 'diamond_fluorescence_intensity_none',
        diamond_diamond_treated: 'diamond_diamond_treated_no',
        diamond_clarity_enhanced: 'diamond_clarity_enhanced_no',
        diamond_color_enhanced: 'diamond_color_enhanced_no',
        diamond_chips: 'diamond_chips_none',
        diamond_inclusions_location: 'diamond_inclusions_location_none',
        diamond_inclusions_level: 'diamond_inclusions_level_eye_clean',
        diamond_culet_size: 'diamond_culet_size_none',
        diamond_culet_condition: 'diamond_culet_condition_natural',
        diamond_certificate: FieldCertificateFromSimple[params.certificate],
        diamond_certificate_number: params.certificateNumber,
        diamond_setting_type: 'diamond_setting_type_prong',
        diamond_carat_weight: `${carat}`,
        diamond_carat_weight_to: `${caratTo ?? ''}`,
        diamond_shape: FieldShapeFromSimple[params.shape],
        diamond_color: FieldColorFromSimple[color],
        diamond_clarity: FieldClarityFromSimple[clarity],
        diamond_type: isLabGrown ? 'diamond_type_lab_grown' : 'diamond_type_natural',
        stone_type: isLabGrown ? 'stone_type_lab_grown_diamond' : 'stone_type_diamond',
      },
    };

    if (params.caratClassification === CLASSIFICATION.GEMSTONE) {
      centerStoneSubset = this.getGemstoneStoneSubset(
        params,
        carat,
        caratTo,
        color,
        clarity,
        'gemstone_center_stone',
      );
      delete centerStoneSubset.properties_set.item_type;
    }

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: 'Rings',
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: '1',
          desc: 'Rings',
          initial_transaction_type: '1',
          subsets: {
            1: {
              type: 'jewelry_rings',
              properties_set: {
                item_type: '1',
                jewelry_style_ring: FieldStyleFromSimple[params.setting],
                ring_size: 'ring_size_6_25',
                jewelry_metal_weight: '5',
                jewelry_brand: FieldBrandFromSimple[params.brand],
              },
            },
            2: centerStoneSubset,
          },
        },
        sessionData: getSessionData(),
        input_props: {
          itemType: 'ring',
          style: params.setting,
          shape: params.shape,
          caratWeight: `${carat}`,
          caratWeightTo: `${caratTo ?? ''}`,
          color,
          clarity,
          certificateType: params.certificate,
          certificateId: params.certificateNumber,
          brand: params.brand,
          submitTimestamp: new Date().toISOString(),
          isMeasurement: params.measureByImg,
        },
      },
      this.defaultOptions,
    );
  }

  static createLooseDiamondItem(params: ItemInputsTypes) {
    const { color, clarity, carat, caratTo, isLabGrown } = this.extracted(params);

    let looseSubset: any = {
      type: 'diamond_loose_diamonds',
      properties_set: {
        item_type: '4',
        diamond_color_shade: 'diamond_color_shade_na',
        diamond_cut_grade: 'diamond_cut_grade_very_good',
        diamond_polish: 'diamond_polish_very_good',
        diamond_symmetry: 'diamond_symmetry_very_good',
        diamond_fluorescence_intensity: 'diamond_fluorescence_intensity_none',
        diamond_diamond_treated: 'diamond_diamond_treated_no',
        diamond_clarity_enhanced: 'diamond_clarity_enhanced_no',
        diamond_color_enhanced: 'diamond_color_enhanced_no',
        diamond_chips: 'diamond_chips_none',
        diamond_inclusions_location: 'diamond_inclusions_location_none',
        diamond_inclusions_level: 'diamond_inclusions_level_eye_clean',
        diamond_culet_size: 'diamond_culet_size_none',
        diamond_culet_condition: 'diamond_culet_condition_natural',
        diamond_certificate: FieldCertificateFromSimple[params.certificate],
        diamond_certificate_number: params.certificateNumber,
        diamond_setting_type: 'diamond_setting_type_prong',
        diamond_carat_weight: `${carat}`,
        diamond_carat_weight_to: `${caratTo ?? ''}`,
        diamond_shape: FieldShapeFromSimple[params.shape],
        diamond_color: FieldColorFromSimple[color],
        diamond_clarity: FieldClarityFromSimple[clarity],
        diamond_type: isLabGrown ? 'diamond_type_lab_grown' : 'diamond_type_natural',
      },
    };

    if (params.caratClassification === CLASSIFICATION.GEMSTONE) {
      // @ts-ignore
      looseSubset = this.getGemstoneStoneSubset(
        params,
        carat,
        caratTo,
        color,
        clarity,
        'gemstone_loose_gemstones',
        '7',
      );
      delete looseSubset.properties_set.stone_type;
    }

    const itemType =
      params.caratClassification === CLASSIFICATION.GEMSTONE ? 'Loose Gemstone' : 'Loose Diamonds';

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: itemType,
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: params.caratClassification === CLASSIFICATION.GEMSTONE ? '7' : '1',
          desc: itemType,
          initial_transaction_type: '1',
          subsets: {
            1: looseSubset,
          },
        },
        sessionData: getSessionData(),
        input_props: {
          itemType: ItemTypes.LooseDiamond,
          style: params.setting,
          shape: params.shape,
          caratWeight: carat,
          color,
          clarity,
          certificateType: params.certificate,
          certificateId: params.certificateNumber,
          brand: params.brand,
          submitTimestamp: new Date().toISOString(),
        },
      },
      this.defaultOptions,
    );
  }

  private static extracted(params: ItemInputsTypes) {
    const isLabGrown = params.caratClassification === CLASSIFICATION.LAB_GROWN;
    const isGemstone = params.caratClassification === CLASSIFICATION.GEMSTONE;

    const color = params.color || (isGemstone ? GemstoneColor.NA : DiamondColor.NotSure);
    const clarity = params.clarity || DiamondClarity.NotSure;

    let { carat } = params;
    let caratTo = params.caratRangeTo;

    // Carat is 0 if the user selected carat 'Not Sure'.
    // In this case, we set carat and caratTo to the same value (0.99),
    // to flag the API that the user selected 'Not Sure'.
    // The api will then mark the item as pending for photo approval (instead of auto qualified lead).
    if (carat === 0) {
      if (isLabGrown) {
        carat = 3.0;
        caratTo = 3.0;
      } else if (isGemstone) {
        carat = 2.0;
        caratTo = 2.0;
      } else {
        carat = 0.99;
        caratTo = 0.99;
      }
    }
    return { color, clarity, carat, caratTo, isLabGrown };
  }

  static async createBraceletItem(params: SubmitState) {
    const isCustomBrand = params.brand === 'other' && params.customBrand.length > 0;
    const customBrandName = params.customBrand.trim();

    const inputProps = {
      itemType: ItemTypes.Bracelet,
      style: params.style,
      brand: params.brand,
      shape: DiamondShape.Other,
      color: params.color,
      clarity: params.clarity,
      caratWeightTotal: params.totalCarat,
      metalType: params.metalType,
      numDiamonds: params.diamondNumber.toString(),
      submitTimestamp: new Date().toISOString(),
      ...(isCustomBrand && { customName: customBrandName }),
    };

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: 'Bracelets',
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: '1',
          desc: 'Bracelets',
          premium_brand: params.premiumBrand,
          // initial_transaction_type: '1',
          subsets: {
            1: {
              type: 'jewelry_bracelets',
              properties_set: {
                item_type: '7',
                jewelry_style_bracelet: FieldBraceletStyleFromSimple[params.style],
                jewelry_metal_type: params.metalType
                  ? FieldMetaTypeFromSimple[params.metalType]
                  : 'jewelry_metal_type_other',
                ...(params.metalWeight && { jewelry_metal_weight: params.metalWeight }),
                ...(params.brand && { jewelry_brand: FieldBrandFromSimple[params.brand] }),
                ...(params.box && {
                  jewelry_original_box_papers: `jewelry_original_box_papers_${params.box.toLowerCase()}`,
                }),
                ...(params.papers && {
                  jewelry_includes_papers: `jewelry_includes_papers_${params.papers.toLowerCase()}`,
                }),
                ...(isCustomBrand && {
                  jewelry_custom_brand_name: customBrandName,
                }),
              },
            },
            2: {
              type: 'diamond_minor_stone_batch',
              properties_set: {
                diamond_number_of_stones: params.diamondNumber.toString(),
                diamond_total_carat_weight: params.totalCarat.toString(),
                diamond_shape: FieldShapeFromSimple[DiamondShape.Other],
                diamond_average_color_grade: 'diamond_average_color_grade_not_sure',
                diamond_average_clarity_grade: 'diamond_average_clarity_grade_not_sure',
              },
            },
          },
        },
        sessionData: getSessionData(),
        input_props: inputProps,
      },
      this.defaultOptions,
    );
  }

  static async createNecklaceItem(params: SubmitState) {
    const isCustomBrand = params.brand === 'other' && params.customBrand.length > 0;
    const customBrandName = params.customBrand.trim();
    let secondSubset = null;
    let inputProps = {
      itemType: ItemTypes.Necklace.toLowerCase(),
      style: params.style.toLowerCase(),
      brand: params.brand.toLowerCase(),
      shape: params.shape.toLowerCase(),
      color: DiamondColor.NotSure.toLowerCase(),
      clarity: DiamondClarity.NotSure.toLowerCase(),
      submitTimestamp: new Date().toISOString(),
      ...(isCustomBrand && { customName: customBrandName }),
    };

    const SubsetStyle = SubsetForNecklaceStyle[params.style];
    if (SubsetStyle === Subsets.CenterStone) {
      inputProps = {
        ...inputProps,
        ...{
          metalType: FieldMetaTypeFromSimple[params.metalType],
          caratWeight: params.carat,
        },
      };

      secondSubset = {
        type: SubsetStyle,
        properties_set: {
          diamond_shape: FieldShapeFromSimple[params.shape],
          diamond_carat_weight: params.carat,
          diamond_color: FieldColorFromSimple[DiamondColor.NotSure],
          diamond_clarity: FieldClarityFromSimple[DiamondClarity.NotSure],
        },
      };
    }
    if (SubsetStyle === Subsets.MinorStoneBatch) {
      inputProps = {
        ...inputProps,
        ...{
          numDiamonds: params.diamondNumber,
          caratWeightTotal: params.totalCarat,
        },
      };

      secondSubset = {
        type: SubsetStyle,
        properties_set: {
          diamond_number_of_stones: params.diamondNumber,
          diamond_total_carat_weight: params.totalCarat,
          diamond_shape: FieldShapeFromSimple[params.shape],
          diamond_average_color_grade: 'diamond_average_color_grade_na',
          diamond_average_clarity_grade: 'diamond_average_clarity_grade_na',
          diamond_certificate: FieldCertificateFromSimple[CertificateType.None],
        },
      };
    }

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: 'Necklace',
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: '1',
          desc: 'Necklace',
          premium_brand: params.premiumBrand,
          initial_transaction_type: '1',
          subsets: {
            1: {
              type: 'jewelry_necklaces',
              properties_set: {
                item_type: '1',
                jewelry_style_necklace: FieldNecklaceStyleFromSimple[params.style],
                jewelry_metal_type: params.metalType
                  ? FieldMetaTypeFromSimple[params.metalType]
                  : 'jewelry_metal_type_other',
                ...(params.metalWeight && { jewelry_metal_weight: params.metalWeight }),
                ...(params.brand && { jewelry_brand: FieldBrandFromSimple[params.brand] }),
                ...(params.box && {
                  jewelry_original_box_papers: `jewelry_original_box_papers_${params.box.toLowerCase()}`,
                }),
                ...(params.papers && {
                  jewelry_includes_papers: `jewelry_includes_papers_${params.papers.toLowerCase()}`,
                }),
                ...(isCustomBrand && {
                  jewelry_custom_brand_name: customBrandName,
                }),
              },
            },
            ...(secondSubset && { 2: secondSubset }),
          },
        },
        sessionData: getSessionData(),
        input_props: inputProps,
      },
      this.defaultOptions,
    );
  }

  static async createWatchItem(params: SubmitState) {
    const inputProps = {
      itemType: ItemTypes.Watch.toLowerCase(),
      brand: params.brand.toLowerCase(),
      condition: params.condition.toLowerCase(),
      papers: params.papers.toLowerCase(),
      model: params.model.toLowerCase(),
      box: params.box.toLowerCase(),
      bandMaterial: params.bandMaterial.toLowerCase(),
      caseMaterial: params.caseMaterial.toLowerCase(),
      gender: params.gender.toLowerCase(),
      submitTimestamp: new Date().toISOString(),
    };

    const dataSet = {
      item_type: '3',
      luxury_watch_brand: FieldWatchBrandFromSimple[params.brand],
      luxury_watch_condition: FieldConditionFromSimple[params.condition],
      luxury_watch_includes_papers: `luxury_watch_includes_papers_${inputProps.papers}`,
      luxury_watch_model: params.model,
      luxury_watch_original_box_papers: `luxury_watch_original_box_papers_${inputProps.papers}`,
      // luxury_watch_band_material: FieldBandMaterialFromSimple[params.bandMaterial],
      // luxury_watch_case_material: FieldCaseMaterialFromSimple[params.caseMaterial],
      // luxury_watch_gender: `luxury_watch_gender_${inputProps.gender}`,
    };

    if (params.brand === 'other') {
      Object.assign(dataSet, { luxury_watch_brand_custom_name: params.customBrand });
      Object.assign(inputProps, { customName: params.customBrand });
    }

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: 'Watches',
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: '3',
          desc: 'Watches',
          premium_brand: params.premiumBrand,
          initial_transaction_type: '1',
          subsets: {
            1: {
              type: 'luxury_watch_watches',
              properties_set: dataSet,
            },
          },
        },
        sessionData: getSessionData(),
        input_props: inputProps,
      },
      this.defaultOptions,
    );
  }

  static async createEarringsItem(params: SubmitState) {
    const isCustomBrand = params.brand === 'other' && params.customBrand.length > 0;
    const customBrandName = params.customBrand.trim();

    let secondSubset = null;
    let inputProps = {
      itemType: 'earring',
      styleEarrings: params.style.toLowerCase(),
      metalType: FieldMetaTypeFromSimple[MetalTypes.Other],
      metalWeight: 0,
      brand: params.brand.toLowerCase(),
      ...(isCustomBrand && { customName: customBrandName }),
    };

    const SubsetStyle = SubsetForEarringsStyle[params.style];
    if (SubsetStyle === Subsets.CenterStone) {
      inputProps = {
        ...inputProps,
        ...{
          caratWeight: params.totalCarat,
          certificateType: params.certificate,
          submitTimestamp: new Date().toISOString(),
        },
      };

      secondSubset = {
        type: SubsetStyle,
        properties_set: {
          diamond_shape: FieldShapeFromSimple[params.shape],
          diamond_carat_weight: params.carat,
          diamond_color: FieldColorFromSimple[DiamondColor.NotSure],
          diamond_clarity: FieldClarityFromSimple[DiamondClarity.NotSure],
          diamond_certificate: FieldCertificateFromSimple[params.certificate],
          diamond_certificate_number: params.certificateNumber,
        },
      };
    }
    if (SubsetStyle === Subsets.MinorStoneBatch) {
      inputProps = {
        ...inputProps,
        ...{
          itemType: 'earring',
          style: params.style,
          numberOfStones: params.diamondNumber,
          totalCaratWeight: params.totalCarat,
          caratWeight: params.carat,
          shape: params.shape,
          certificateType: params.certificate,
          certificateId: params.certificateNumber,
          submitTimestamp: new Date().toISOString(),
        },
        ...(params.brand && { brand: params.brand }),
      };
      secondSubset = {
        type: SubsetStyle,
        properties_set: {
          diamond_number_of_stones: params.diamondNumber,
          diamond_total_carat_weight: params.totalCarat,
          diamond_shape: FieldShapeFromSimple[params.shape],
          diamond_average_color_grade: 'diamond_average_color_grade_na',
          diamond_average_clarity_grade: 'diamond_average_clarity_grade_na',
          diamond_certificate: FieldCertificateFromSimple[CertificateType.None],
        },
      };
    }

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: 'Earrings',
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: '1',
          desc: 'Earrings',
          premium_brand: params.premiumBrand,
          initial_transaction_type: '1',
          subsets: {
            1: {
              type: 'jewelry_earrings',
              properties_set: {
                item_type: '1',
                jewelry_style_earring: FieldEarringsStyleFromSimple[params.style],
                jewelry_metal_type: params.metalType
                  ? FieldMetaTypeFromSimple[params.metalType]
                  : 'jewelry_metal_type_other',
                ...(params.metalWeight && { jewelry_metal_weight: params.metalWeight }),
                ...(params.brand && { jewelry_brand: FieldBrandFromSimple[params.brand] }),
                ...(params.box && {
                  jewelry_original_box_papers: `jewelry_original_box_papers_${params.box.toLowerCase()}`,
                }),
                ...(params.papers && {
                  jewelry_includes_papers: `jewelry_includes_papers_${params.papers.toLowerCase()}`,
                }),
                ...(isCustomBrand && {
                  jewelry_custom_brand_name: customBrandName,
                }),
              },
            },
            ...(secondSubset && { 2: secondSubset }),
          },
        },
        sessionData: getSessionData(),
        // worthy-personalizer can't process event with earring in implementation moment
        input_props: inputProps,
      },
      this.defaultOptions,
    );
  }

  static async register(params: SubmitState['registration'], bundleId: number) {
    const data = {
      first_name: params.firstName,
      last_name: params.lastName,
      email: params.email,
      phone: params.phoneNumber || DEFAULT_PHONE,
      referer: CookieStore.getReferer(),
      friendbuy_referer: CookieStore.getRefererFriendbuy(),
      contact_preference: 'phone_call',
      bundle_id: bundleId,
      user_flow: null, // ?
      optional_phone_exp: true,
      sms_opt_in: params.smsConsent || false,
    };

    if (params.observableActions?.copyPastFields?.length) {
      (data as any).observable_actions = {
        copy_past_fields: params.observableActions.copyPastFields.map(toSnakeCase),
      };
    }

    return axios.post(
      `${REACT_APP_SITE_URL}/api/v2/sellers/registration`,
      data,
      this.defaultOptions,
    );
  }

  static async updatePhoneNumber(params: SubmitState['registration'], userId: string | undefined) {
    return axios.post(
      `${REACT_APP_SITE_URL}/api/v2/sellers/user/update_missing_details`,
      {
        phone: params.phoneNumber || DEFAULT_PHONE,
        user_id: userId,
      },
      this.defaultOptions,
    );
  }

  static async uploadImage(itemId: string, data: FormData) {
    return axios
      .post<
        any,
        AxiosResponse<{
          paths: string[]; // 'https://cdn1.worthy.com/item_submission_photo/864297/1'
        }>
      >(`${REACT_APP_SITE_URL}/api/v2/sellers/item/${itemId}/upload_images`, data, {
        ...this.defaultOptions,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((r) => {
        if (r.status === 200 && r.data.paths) {
          return r.data;
        }
        throw new Error('uploadImage failed');
      });
  }

  static async removeImages(files: string[], itemId: string) {
    return axios
      .post<
        any,
        AxiosResponse<{
          removed: boolean; // true
          status: string; // 'ok'
        }>
      >(
        `${REACT_APP_SITE_URL}/api/v2/sellers/item/${itemId}/remove_images`,
        {
          files,
        },
        this.defaultOptions,
      )
      .then((r) => {
        if (r.status === 200) {
          return r.data;
        }
        throw new Error('removeImage failed');
      });
  }

  static async estimate(itemId: string) {
    return axios
      .get(`${REACT_APP_SITE_URL}/api/v2/sellers/item/${itemId}/estimate`, this.defaultOptions)
      .then((r) => r.data);
  }

  static async generateItemEstimationByPublicId(itemId: string, measureByImg: boolean) {
    return axios
      .post(
        `${REACT_APP_SITE_URL}/api/v2/sellers/item/${itemId}/generate_estimation`,
        {
          isMeasurement: measureByImg,
        },
        this.defaultOptions,
      )
      .then((r) => r.data);
  }

  static async registerWithGoogle(credential: string) {
    return axios.post(
      `${REACT_APP_SITE_URL}/api/v2/sellers/register_with_google`,
      { credential },
      {
        withCredentials: true,
      },
    );
  }

  static async getUserDataFromGoogleCredential(credential: string) {
    return axios
      .get(
        `${process.env.REACT_APP_SUBMIT_BE_URL}/get-user-data-from-google-credential?credential=${credential}`,
        this.defaultOptions,
      )
      .then((r) => r.data);
  }
}
